import React, { useState, useEffect, useRef } from 'react';
import { QrReader } from 'react-qr-reader';
import { Box, Heading, Text, Container, Button, Spinner } from '@chakra-ui/react';
import jsPDF from 'jspdf';
import QRCode from 'qrcode';

const ScannerPage = ({ envCode, envURL }) => {
  const [scanResult, setScanResult] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [printer, setPrinter] = useState(null);
  const [facingMode, setFacingMode] = useState('user');

  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'environment' ? 'user' : 'environment'));
  };

  const [badgeData, setBadgeData] = useState({
    first_name: null,
    last_name: null,
    job_title: null,
    company: null
  });

  const handleError = (err) => {
    console.error(err);
  };

  const envURLRef = useRef(envURL);

  useEffect(() => {
    envURLRef.current = envURL;
    // envURLRef.current = "http://localhost:4500";

  }, [envURL]);

  function extractIdsFromUrl(url) {
    const regex = /event\/(\d+)\/user\/(\d+)/;
    const match = url.match(regex);

    if (match) {
      return {
        eventId: match[1],
        userId: match[2]
      };
    } else {
      return {
        eventId: null,
        userId: null
      };
    }
  }

  function processScan(data) {
    setScanResult(data?.text);
    const ids = extractIdsFromUrl(data?.text);
    setEventId(ids.eventId);
    setUserId(ids.userId);
    setQrCode(data?.text);

    if (ids.eventId && ids.userId) {
      let api = `${envURLRef.current}/api/v1/event-connection-attendees?event_id=${ids.eventId}&limit=1&user_id=${ids.userId}`;

      fetch(api, {
        method: 'GET',
        headers: {
          'third-party-attendees-api-key': 'WQgq5$KnHCu1',
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          if (data.attendees.length) {
            setBadgeData({
              first_name: data.attendees[0].first_name,
              last_name: data.attendees[0].last_name,
              job_title: data.attendees[0].job_title,
              company: data.attendees[0].company
            });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  const handlePrint = async () => {
    setIsLoading(true);
    
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: [101.6, 152.4]
    });
    
    doc.setFontSize(11);

    const pageHeight = doc.internal.pageSize.getHeight();
    
    const margin = 5;
    const lineHeight = 5;
    const qrCodeSize = 25;
    const columnGap = 5;

    const trimText = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength - 3) + '...';
      }
      return text;
    };
    
    // Function to add QR code and text block
    const addQRCodeAndText = (startX, startY, badgeData, qrCodeDataUrl, rotate) => {
      const qrCodeX = startX + margin;
      const qrCodeY = startY + margin;
    
      // Calculate total height of text block
      const textLines = [
        `${badgeData.first_name} ${badgeData.last_name}`,
        `${badgeData.job_title}`,
        `${badgeData.company}`
      ];
      const textBlockHeight = textLines.length * lineHeight;
    
      // Center the text block vertically relative to the QR code
      let currentY = qrCodeY + (qrCodeSize - textBlockHeight) / 2;
    
      // Adjust the startX for the text column
      const textColumnX = qrCodeX + qrCodeSize + columnGap;
    
      // Function to add text in the right column
      const addRightColumnText = (text) => {
        const trimmedText = trimText(text, 33);
        doc.text(trimmedText, rotate ? textColumnX + 53 : textColumnX -2, currentY + 2, null, rotate);
        currentY += lineHeight;
      };
    
      // Add text in the right column
      textLines.forEach(line => addRightColumnText(line));
    
      // Add QR code on the left
      doc.addImage(qrCodeDataUrl, 'JPEG', qrCodeX + 2, qrCodeY, qrCodeSize, qrCodeSize);
    };
    
    // Generate QR code data URL
    const qrCodeDataUrl = await QRCode.toDataURL(qrCode);
    
    // Add content for the first badge (top half)
    addQRCodeAndText(0, 25, badgeData, qrCodeDataUrl, 0);
    
    // Add content for the second badge (bottom half)
    addQRCodeAndText(0, 68, badgeData, qrCodeDataUrl, 180);

    const pdfBlob = doc.output('blob');
    const formData = new FormData();
    formData.append('file', pdfBlob, 'badge.pdf');

    fetch('https://firefly-amusing-surely.ngrok-free.app/upload', {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log('response:', data);
        setUploadResponse(data);
        setIsLoading(false);
        setIsSuccess(true);
        setPrinter(data.printer);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  };

  const handleReset = () => {
    setScanResult(null);
    setBadgeData({
      first_name: null,
      last_name: null,
      job_title: null,
      company: null
    });
    setUploadResponse(null);
    setIsSuccess(false);
    setPrinter(null);
  };

  return (
    <Container height="100vh">
      {!isSuccess ? (
    <div>
      <div onClick={toggleCamera}>
      <QrReader
      videoContainerStyle={{ paddingTop: '0px' }}
      constraints={{ facingMode }}
      onClick={toggleCamera}
      onResult={(result) => {
        if (result) {
          processScan(result);
        }
      }}
      delay={300}
      onError={handleError}
      videoStyle={{
        position: 'relative',
        objectFit: 'cover',
        width: '300px',
        height: '300px',
        borderRadius: 5,
        margin: '100px auto 50px'
      }}
    />
      </div>
  
  </div>
      ) : (
        <Box textAlign="center" mt="100px">
          <lord-icon
            src="./printer.json"
            trigger="loop"
            colors="primary:#3182ce,secondary:#fff"
            style={{ width: 300, height: 300, margin: '0 auto 50px' }}
          ></lord-icon>
        </Box>
      )}

      {scanResult && (
        <Box textAlign={'center'}>
          <Box mb="20px" color="white">
            <Heading as="h3" size="lg" style={{maxWidth:'500px', margin:'0 auto 5px'}}>
              {isSuccess ? `Welcome ${badgeData.first_name},` : 'Check Your Details'}
            </Heading>
            <Heading size="lg">{isSuccess && ` please go to ${printer}`}</Heading>
          </Box>
         
          <Text color="white">
            Name: {badgeData?.first_name} {badgeData?.last_name}
          </Text>
          <Text color="white">Job Title: {badgeData?.job_title}</Text>
          <Text color="white" mb="10px">
            Company: {badgeData?.company}
          </Text>
          <Box display="flex" alignItems={'center'} flexDirection={'column'}>
            {!isLoading && !isSuccess && (
              <Button onClick={handlePrint} mt="20px" colorScheme="blue" w="220px">
                Print Badge
              </Button>
            )}
            {isLoading && <Spinner mt="20px" />}
            <Button onClick={handleReset} mt="20px" colorScheme="blue" w="220px">
              New Scan
            </Button>
            {uploadResponse && (
              <Text mt="20px" color="white">
                {uploadResponse.status}
              </Text>
            )}
          </Box>
        </Box>
      )}

      {!scanResult && !isSuccess && (
        <Box textAlign={'center'}>
          <Heading as="h3" size="lg" mb="10px" color="white">
            Scan Your Code
          </Heading>
        </Box>
      )}
    </Container>
  );
};

export default ScannerPage;
