import 'webrtc-adapter';
import React, { useState, useEffect } from 'react';
import EnvCodePage from './EnvCodePage';
import ScannerPage from './ScannerPage';
import { ChakraProvider, Box } from '@chakra-ui/react';

function App() {
  const [envCode, setEnvCode] = useState('');
  const [isEnvCodeEntered, setIsEnvCodeEntered] = useState(false);
  const [envURL, setEnvUrl] = useState(null);

  const handleEnvCodeSubmit = (code) => {
    setEnvCode(code.toUpperCase());
    setIsEnvCodeEntered(true);
  };

  useEffect(() => {
    if (envCode) {
      console.log(`Fetching config for envCode: ${envCode}`);
      fetch(`https://totem-env.s3.eu-west-2.amazonaws.com/app/${envCode}/config.json`, {
        method: 'GET'
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched config data:', data);
        setEnvUrl(data.auth);
      })
      .catch((error) => {
        console.error('Error fetching config:', error);
      });
    }
  }, [envCode]);

  return (
    <ChakraProvider>
      <Box background="#0d2245">
        {!isEnvCodeEntered ? (
          <EnvCodePage onSubmit={handleEnvCodeSubmit} />
        ) : (
          <ScannerPage envCode={envCode} envURL={envURL} />
        )}
      </Box>
    </ChakraProvider>
  );
}

export default App;
