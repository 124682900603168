import React, { useState } from 'react';
import { Container, Box, Input, Button, FormControl, FormLabel, Image, Text } from '@chakra-ui/react';

const EnvCodePage = ({ onSubmit }) => {
  const [envCode, setEnvCode] = useState('');

  const handleEnvCodeChange = (e) => {
    setEnvCode(e.target.value);
  };

  const handleEnvCodeSubmit = (e) => {
    e.preventDefault();
    if (envCode) {
      onSubmit(envCode);
    }
  };

  return (
    <Container
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box textAlign={'center'}>
        <Box width='300px' m='30px auto' background='#fff' style={{padding:'30px 10px', borderRadius:5}}>
        <Image src='./logo.png'></Image>
        </Box>
        <form onSubmit={handleEnvCodeSubmit}>
          <FormControl>
            <FormLabel color='white'>Enter Environment Code:</FormLabel>
            <Input
              color={'#fff'}
              type="text"
              value={envCode}
              onChange={handleEnvCodeChange}
              width="300px"
              margin="0 auto"
            />
          </FormControl>
          <Button type="submit" mt="20px" w='100%'>
            Submit
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default EnvCodePage;
